<template>
  <Html lang="de">
    <Body
      class="antialiased duration-300 transition-colors text-gray-800 dark:text-gray-200 bg-white dark:bg-stone-950 overscroll-y-none scrollbar-thin scrollbar-thumb-stone-900 scrollbar-track-stone-800"
    >
      <NuxtLayout>
        <NuxtLoadingIndicator :height="5" :duration="3000" :throttle="400" />
        <NuxtPage />
      </NuxtLayout>
    </Body>
  </Html>
</template>
