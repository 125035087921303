import { default as _91_46_46_46slug_93yXYr45hWo4Meta } from "/var/www/vhosts/kedian.de/httpdocs/frontend/pages/[...slug].vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93yXYr45hWo4Meta?.name ?? "slug",
    path: _91_46_46_46slug_93yXYr45hWo4Meta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93yXYr45hWo4Meta || {},
    alias: _91_46_46_46slug_93yXYr45hWo4Meta?.alias || [],
    redirect: _91_46_46_46slug_93yXYr45hWo4Meta?.redirect,
    component: () => import("/var/www/vhosts/kedian.de/httpdocs/frontend/pages/[...slug].vue").then(m => m.default || m)
  }
]