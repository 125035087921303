import revive_payload_client_4sVQNw7RlN from "/var/www/vhosts/kedian.de/httpdocs/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/vhosts/kedian.de/httpdocs/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/vhosts/kedian.de/httpdocs/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_9gMxCtyFUG from "/var/www/vhosts/kedian.de/httpdocs/frontend/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/var/www/vhosts/kedian.de/httpdocs/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/vhosts/kedian.de/httpdocs/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/vhosts/kedian.de/httpdocs/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/vhosts/kedian.de/httpdocs/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/vhosts/kedian.de/httpdocs/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_03YjkxYbK5 from "/var/www/vhosts/kedian.de/httpdocs/frontend/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import plugin_tbFNToZNim from "/var/www/vhosts/kedian.de/httpdocs/frontend/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_5drvKYmdqw from "/var/www/vhosts/kedian.de/httpdocs/frontend/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.mjs";
import plugin_eTVJQYlCmx from "/var/www/vhosts/kedian.de/httpdocs/frontend/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/vhosts/kedian.de/httpdocs/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import aos_client_rDSYU13oOX from "/var/www/vhosts/kedian.de/httpdocs/frontend/plugins/aos.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_9gMxCtyFUG,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt_plugin_03YjkxYbK5,
  plugin_tbFNToZNim,
  plugin_5drvKYmdqw,
  plugin_eTVJQYlCmx,
  chunk_reload_client_UciE0i6zes,
  aos_client_rDSYU13oOX
]