<template>
  <Html lang="de" class="h-full">
    <Body
      class="antialiased duration-300 transition-colors text-gray-800 dark:text-gray-200 bg-white dark:bg-stone-950 overscroll-y-none h-full"
    >
      <NuxtLayout class="h-full">
        <PageWrapper class="h-full">
          <PageBody class="h-full">
            <PageSection class="h-full mt-20">
              <div
                v-if="error.statusCode === 404"
                class="w-full h-full flex flex-col justify-center items-center"
              >
                <div class="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
                  <p class="text-base font-semibold leading-8 text-primary-400">
                    {{ error.statusCode }}
                  </p>
                  <h1
                    class="mt-4 text-3xl font-bold tracking-tight text-white-900 sm:text-5xl"
                  >
                    Diese Seite existiert nicht.
                  </h1>
                  <p
                    class="mt-4 text-base leading-7 text-gray-300 sm:mt-6 sm:text-lg sm:leading-8"
                  >
                    Leider konnten wir die von Ihnen gesuchte Seite nicht
                    finden.
                  </p>
                </div>
                <button class="mt-5">
                  <div class="mt-10 flex justify-center">
                    <a
                      class="text-sm font-semibold leading-6 text-primary-400 hover:text-primary-500"
                      @click.prevent="handleError"
                    >
                      <span aria-hidden="true">&larr;</span>
                      Zurück zur Startseite
                    </a>
                  </div>
                </button>
              </div>
              <div
                v-else
                class="w-full flex flex-col justify-center items-center bg-[#1A2238]"
              >
                <div class="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
                  <p class="text-base font-semibold leading-8 text-primary-400">
                    {{ error.statusCode }}
                  </p>
                  <h1
                    class="mt-4 text-3xl font-bold tracking-tight text-white-900 sm:text-5xl"
                  >
                    Die Seite ist zurzeit nicht erreichbar.
                  </h1>
                  <p
                    class="mt-4 text-base leading-7 text-gray-300 sm:mt-6 sm:text-lg sm:leading-8"
                  >
                    Bitte versuchen Sie es später erneut.
                  </p>
                </div>
                <button class="mt-5">
                  <div class="mt-10 flex justify-center">
                    <a
                      class="text-sm font-semibold leading-6 text-primary-400 hover:text-primary-500"
                      @click.prevent="handleError"
                    >
                      <span aria-hidden="true">&larr;</span>
                      Zurück zur Startseite
                    </a>
                  </div>
                </button>
              </div>
            </PageSection>
          </PageBody>
        </PageWrapper>
      </NuxtLayout>
    </Body>
  </Html>
</template>

<script setup>
const props = defineProps({
  error: {
    type: Object,
    required: true,
  },
})
const handleError = () => clearError({ redirect: '/' })
</script>
